import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectSiteUrl } from 'core/selectors/app';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import { useDropdown } from 'core/hooks/useDropdown';

import { useGetPlacements } from 'core/modules/Navigation/NavSeasonalFlyout/hooks';
import HeaderExtraRewards from 'core/modules/Header/HeaderExtraRewards/HeaderExtraRewards';

export function ExtraRewardsSectionWithDropdown() {
  const activeMQ = useSelector(selectActiveMQ);
  const siteUrl = useSelector(selectSiteUrl);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();
  const placements = useGetPlacements();
  const merchants = placements.map(({ merchant }) => merchant);

  if (!isEmpty(merchants) && ['XL', 'XXL'].includes(activeMQ)) {
    return (
      <div
        onMouseEnter={showSubnav}
        onMouseLeave={hideSubnav}
        onBlur={hideSubnav}
        className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
      >
        <a
          className="mn_navigationItem"
          href={`${siteUrl}/p/extra-rewards.htm`}
          title="Opens extra rewards page"
          aria-expanded={isFocused}
          onFocus={showSubnav}
        >
          <i className="mn_usaaSVGIconExtraRewards" aria-hidden="true" />
        </a>
        <div className="mn_navigationDropdown mn_extraRewardsNavigationDropdown">
          <div className="mn_extraRewardsDropdown">
            <HeaderExtraRewards merchants={merchants} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mn_navigationDropdownTrigger">
      <a className="mn_navigationItem" href={`${siteUrl}/p/extra-rewards.htm`} title="Opens extra rewards page">
        <i className="mn_usaaSVGIconExtraRewards" aria-hidden="true" />
      </a>
    </div>
  );
}

export default ExtraRewardsSectionWithDropdown;
