import React from 'react';
import { useSelector } from 'react-redux';

import { useGetNavigation } from 'core/modules/Navigation/Nav/hooks';
import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';

import { selectSiteUrl } from 'core/selectors/app';

import './NavOffersTOF.scss';

export function NavOffersTOF() {
  const siteUrl = useSelector(selectSiteUrl);
  const { navItemsFeatured, navItemsNonFeatured } = useGetNavigation();
  const gunSafetyItem = { title: 'Gun Safety', url: `${siteUrl}/sr____.htm?keywords=gun%20safes` };
  const topNavItems =
    [...navItemsFeatured.slice(0, 1), gunSafetyItem, ...navItemsFeatured.slice(1)];

  return (
    <div className="mn_navOffersTOF">
      <h2>Shop by Offer</h2>
      <section className="mn_navOffersList">
        <ul className="mn_navDropdownMenu">
          {topNavItems && topNavItems.map(navItem => (
            <NavItem
              key={navItem.title.toString()}
              title={navItem.title}
              url={navItem.url}
              extraRewardsLabel="Extra Cash Back"
            />
          ))}
          {navItemsNonFeatured && navItemsNonFeatured.map(navItem => (
            <NavItem
              key={navItem.title.toString()}
              title={navItem.title}
              url={navItem.url}
              extraRewardsLabel="Extra Cash Back"
            />
          ))}
          <NavItem title="All Online Offers" url={`${siteUrl}/sp____.htm`} />
          <NavItem title="Free Shipping" url={`${siteUrl}/fs____Free%20Shipping.htm`} />
        </ul>
      </section>
    </div>
  );
}

export default NavOffersTOF;
